<template>
  <ul v-if="isloaded && address != '--'">
    <li
      v-if="
        checkProperty(address, 'addr1') == '--' ||
          checkProperty(address, 'addr2') != ''
      "
    >
      <label>Address</label>
      <p>
        {{ checkProperty(address, "addr1")
        }}<template v-if="address.addr1 && address.addr2"> , </template>
        {{ checkProperty(address, "addr2") }}
      </p>
    </li>
    <li>
      <label>Country</label>
      <p>{{ checkProperty(address, "countryDetails", "name") }}</p>
    </li>
    <li>
      <label>State</label>
      <p>
        {{ checkProperty(address, "stateDetails", "name") }}
      </p>
    </li>
    <li>
      <label>City</label>
      <p>
        {{ checkProperty(address, "locationDetails", "name") }}
      </p>
    </li>
    <li>
      <label>Zip</label>
      <p>
        {{ checkProperty(address, "zip") }}
      </p>
    </li>
  </ul>
</template>
<script>
import _ from "lodash";

export default {
  name: "addressViewComponent",
  props: {
    getSandC: {
      type: Boolean,
      default: true,
    },
    address: {
      type: [Object, String],
      default: null,
    },
    countryesData: {
      type: Array,
      default: null,
    },
    statesData: {
      type: Array,
      default: null,
    },
    cityesData: {
      type: Array,
      default: null,
    },

    /*
    address:{
        "addr1": "",
        "addr2": "",
        "stateId": null,
        "cityId": null,
        "zip": "",
        "cntryId":null,
        "countryDetails":{ "_id": '', "name": "" },
        "stateDetails":{ "_id": '', "name": "" },
        "locationDetails":{ "_id": '', "name": "" }
        },
        */
  },
  data() {
    return {
      isloaded: false,
    };
  },
  mounted() {
    this.isloaded = true;
    if (this.getSandC) {
      this.isloaded = false;
      //get data from countryesData ,statesData and cityesData these and overwrite address veriable accordingy
      if (_.has(this.address, "cntryId") && this.countryesData != null) {
        let country = _.find(this.countryesData, {
          _id: this.address["cntryId"],
        });
        if (country) {
          this.address["countryDetails"] = country;
        }
      }
      this.isloaded = true;
    }
    if (this.getSandC) {
      this.isloaded = false;
      //get data from countryesData ,statesData and cityesData these and overwrite address veriable accordingy
      if (_.has(this.address, "stateId") && this.statesData != null) {
        let state = _.find(this.statesData, { _id: this.address["stateId"] });
        if (state) {
          this.address["stateDetails"] = state;
        }
      }
      this.isloaded = true;
    }
    if (this.getSandC) {
      this.isloaded = false;
      //get data from countryesData ,statesData and cityesData these and overwrite address veriable accordingy
      if (_.has(this.address, "cityId") && this.cityesData != null) {
        let city = _.find(this.cityesData, { _id: this.address["cityId"] });
        if (city) {
          this.address["locationDetails"] = city;
        }
      }
      this.isloaded = true;
    }
  },
};
</script>
